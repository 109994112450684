<template>
  <div>
    <Pane />

    <a-form :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false" @submit="handleSubmit">
      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-info.png" alt="" />
          <span>基本信息</span>
        </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="经办部门">
              <a-select placeholder="" show-search :filter-option="$selectFilterOption" style="width: 100%" v-decorator="[
                                'operationDeptId',
                                { rules: [{ required: true, message: '请选择！' }] },
                              ]">
                <a-select-option v-for="item in organizationList" :key="item.id" :value="item.id">{{ item.uniqueName
                                  }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">经办人</span>
              <EmployeeSelector @change="(arr) => {
                                if (arr.length > 0) {
                                  selectedUser = arr[0];
                                } else {
                                  selectedUser = {};
                                }
                              }
                              " :value="selectedUser.name ? [selectedUser] : []">
                <a-button block style="text-align: left">
                  <div v-if="selectedUser.name">
                    {{ selectedUser.name }}
                  </div>
                  <div v-else>&nbsp;</div>
                </a-button>
              </EmployeeSelector>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">合同编号</span>
              <a-button style="margin-top: 4px; text-align: left" block>
                {{ selectedContract.code }}
              </a-button>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">合同名称</span>
              <a-button style="margin-top: 4px; text-align: left" block>
                {{ selectedContract.name }}
              </a-button>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="所属区域">
              <a-cascader placeholder="" v-decorator="[
                                'area',
                              ]" :options="areaList" :fieldNames="{
                      label: 'areaName',
                      value: 'key',
                      children: 'children',
                    }" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="分包名称">
              <a-input v-decorator="[
                                'name',
                                { rules: [{ required: true, message: '请输入！' }] },
                              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">分包方</span>
              <CompanySelector @change="(company) => (selectedCompany = company)">
                <a-button block style="margin-top: 4px; text-align: left" class="ellipsis">
                  {{ selectedCompany.name }}
                </a-button>
              </CompanySelector>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合同金额(万元)">
              <a-input-number :formatter="$inputNumberFormat" v-decorator="[
                                'actualAmount',
                                { rules: [{ required: true, message: '请输入！' }] },
                              ]" style="width: 100%" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="用章名称">
              <a-checkbox-group v-decorator="[
                                'sealType',
                                { rules: [{ required: true, message: '请选择！' }] },
                              ]">
                <a-checkbox v-for="item in sealTypeList" :key="item.value" :value="item.value">
                  {{ item.name }}
                </a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-project.png" alt="" />
          <span>分包信息</span>
        </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="项目地点">
              <a-cascader placeholder="" v-decorator="[
                                'location',
                                { rules: [{ required: true, message: '请选择！' }] },
                              ]" :options="cityList" :fieldNames="{
                      label: 'name',
                      value: 'name',
                      children: 'children',
                    }" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="国外">
              <a-input v-decorator="['isAbroad']" />
              <div class="tip">
                <a-icon type="question-circle" />
                项目地点在国外时填写
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="分包属性">
              <a-select v-decorator="[
                                'isneeded',
                                { rules: [{ required: true, message: '请选择！' }] },
                              ]" style="width: 100%" placeholder="" @change="onIsneededChange">
                <a-select-option v-for="item in [
                                  {
                                    name: '设计类-必要分包',
                                    value: 1,
                                  },
                                  {
                                    name: '设计类-非必要分包',
                                    value: 0,
                                  },
                                  {
                                    name: '总承包类分包',
                                    value: 2,
                                  },
                                ]" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="分包类别">
              <a-select v-decorator="[
                                'type',
                                {
                                  rules: [{ required: true, message: '请选择！' }],
                                },
                              ]" style="width: 100%" placeholder="">
                <a-select-option v-for="item in form.getFieldValue('isneeded') === 1
                                  ? isNecessaryTypeList
                                  : form.getFieldValue('isneeded') === 0
                                    ? notNecessaryTypeList
                                    : form.getFieldValue('isneeded') === 2
                                      ? generalSubTypeList
                                      : []" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24" v-if="form.getFieldValue('isneeded') === 0 &&
                      form.getFieldValue('type') === 'JS3'
                      ">
            <a-form-item label="专业类型">
              <a-select v-decorator="[
                                'major',
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: '请选择！',
                                    },
                                  ],
                                },
                              ]" style="width: 100%" placeholder="">
                <a-select-option v-for="item in majorTypes" :key="item.value" :value="item.value">{{ item.name
                                  }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="支付方式">
              <a-input v-decorator="[
                                'paymentMode',
                                { rules: [{ required: true, message: '请输入！' }] },
                              ]" placeholder="">
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-scale.png" alt="" />
          <span>项目分包明细(含预测)</span>
          <div class="extra">
            <a-button type="primary" size="small" @click="addSubpackageVisible = !addSubpackageVisible">添加分包</a-button>
          </div>
        </div>

        <Padding size="large" />

        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <span class="required" slot="label">项目分包明细(含预测)</span>
          <div style="margin-top: 4px">
            <SubpackageList :list="subpackageList" @change="onChangeSubpackageList" :visible="addSubpackageVisible"
              @setVisible="(visible) => (addSubpackageVisible = visible)" :selectedRowKeys="selectedRowKeys"
              @select="(keys) => (selectedRowKeys = keys)" />
          </div>
        </a-form-item>

        <Padding size="large" />
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-file.png" alt="" />
          <span>附件</span>
          <div class="extra">
            <FileUpload @uploaded="uploaded">
              <a-button type="primary" size="small">上传</a-button>
            </FileUpload>
          </div>
        </div>

        <Padding size="large" />

        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <span class="required" slot="label">附件</span>
          <div style="margin-top: 4px">
            <a-table bordered :dataSource="fileList" :pagination="false">
              <a-table-column title="附件名称">
                <template slot-scope="text">
                  {{ text.completePath }}
                </template>
              </a-table-column>
              <a-table-column title="操作" align="center" width="120px">
                <template slot-scope="text, record, index">
                  <a href="#" class="danger" @click.prevent="removeFile(index)">删除</a>
                </template>
              </a-table-column>
            </a-table>
          </div>
        </a-form-item>

        <Padding size="large" />
      </div>

      <div class="center">
        <a-space>
          <a-button type="primary" htmlType="submit" :loading="saveLoading">
            确认归档
          </a-button>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </a-form>

    <ExtraInfo />
  </div>
</template>

<script>
import area from "@/mixins/area";
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import city from "@/mixins/city";

import { update, fetchDetail } from "@/api/subpackage-register";
import { fetchDetail as fetchContractDetail } from "@/api/contract";
import { mapGetters, mapState } from "vuex";

import SubpackageList from "./components/subpackage-list";
import ExtraInfo from "./components/extra-info";

import EmployeeSelector from "@/components/employee-selector";
import CompanySelector from "@/components/company-selector";
import FileUpload from "@/components/file-upload";

export default {
  name: "signSubpackageRegister",
  mixins: [area, organization, watermark, city],

  components: {
    SubpackageList,
    // ScaleList,
    ExtraInfo,
    EmployeeSelector,
    CompanySelector,
    FileUpload,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      selectedUser: {}, // 选择的经办人
      selectedContract: {}, // 选择的合同
      selectedCompany: {}, // 选择的分包方

      subpackageList: [], // 选择的合同下的分包列表
      addSubpackageVisible: false, // 新增分包弹窗
      selectedRowKeys: [], // 选中的分包项

      fileList: [], // 附件列表

      saveLoading: false,
      id: null, // 重新提交时的 ID
      detail: {}, // 重新提交时获取到的数据
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    sealTypeList() {
      return this.findDataDict("sealType");
    },
    isNecessaryTypeList() {
      return this.findDataDict("subType");
    },
    notNecessaryTypeList() {
      return this.findDataDict("notNeedSubType");
    },
    generalSubTypeList() {
      return this.findDataDict("generalSubType");
    },
    // 专业类型
    majorTypes() {
      return this.findDataDict("majorTypes");
    },
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["department"]),
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;

      fetchDetail({ id }).then((res) => {
        console.log("detail", res);
        if (res) {
          this.detail = res;
          // 经办
          this.selectedUser = {
            userId: res.operatorId,
            name: res.operatorName,
          };

          this.selectedContract = {
            code: res.contractCode,
            name: res.contractName,
          };

          const area = [];
          if (res.areaId) {
            area.push(res.areaId);
          }
          if (res.subAreaId) {
            area.push(res.subAreaId);
          }
          if (res.l3AreaId) {
            area.push(res.l3AreaId);
          }

          this.selectedCompany = {
            id: res.companyId,
            name: res.companyName,
          };

          // 项目地点
          const location = [];
          if (res.belongProvince) {
            location.push(res.belongProvince);
          }
          if (res.belongCity) {
            location.push(res.belongCity);
          }
          if (res.district) {
            location.push(res.district);
          }

          this.form.setFieldsValue({
            // 经办
            operationDeptId: res.operationDeptId,

            // 所属区域
            area,

            // 分包方
            name: res.name,
            actualAmount: res.actualAmount / 10000,

            // 用章名称
            sealType: res.sealType?.split(","),

            // 项目地点
            location,
            // 国外
            isAbroad: res.isAbroad,

            isneeded: res.isneeded,
            paymentMode: res.paymentMode,
          });
          this.form.getFieldDecorator("type", {
            initialValue: res.type,
          });
          if (res.major) {
            this.form.getFieldDecorator("major", {
              initialValue: res.major,
            });
          }

          if (Array.isArray(res.subPackagedList)) {
            const newList = res.subPackagedList.map((item, index) => {
              return {
                ...item,
                key: index,
              };
            });
            this.subpackageList = newList;
            this.selectedRowKeys = [
              newList.findIndex((item) => item.id === res.subPackaged.id),
            ];
          }

          this.fileList =
            typeof res.attachments === "string"
              ? res.attachments.split(",").map((item) => {
                  return {
                    completePath: item,
                  };
                })
              : [];
        }
      });

      return;
    } else {
      alert("网址错误！");
    }
  },

  methods: {
    // 选择合同
    onSelectContract(value) {
      fetchContractDetail(value.id).then((res) => {
        if (res) {
          this.selectedContract = res;

          let arr = [];
          if (res.areaId) {
            arr.push(res.areaId);
          }
          if (res.subAreaId) {
            arr.push(res.subAreaId);
          }
          if (res.l3AreaId) {
            arr.push(res.l3AreaId);
          }
          if (arr.length > 0) {
            this.form.setFieldsValue({
              area: arr,
            });
          }

          if (Array.isArray(res.projects) && res.projects.length > 0) {
            if (Array.isArray(res.projects[0].subPackages)) {
              this.onChangeSubpackageList(res.projects[0].subPackages);
            } else {
              this.onChangeSubpackageList([]);
            }
          } else {
            this.onChangeSubpackageList([]);
          }
        }
      });
    },

    onChangeSubpackageList(list = []) {
      this.subpackageList = list.map((item, index) => {
        return {
          ...item,
          key: index,
        };
      });
    },

    // 上传附件
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    removeFile(index) {
      this.fileList.splice(index, 1);
    },

    onIsneededChange() {
      this.form.setFieldsValue({
        type: null,
        major: null,
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);

          if (!this.selectedCompany.id) {
            this.$message.error("请选择分包方！");
            return;
          }

          if (!this.selectedRowKeys.length) {
            this.$message.error("请选择项目分包！");
            return;
          }

          let subPackaged = this.subpackageList[this.selectedRowKeys[0]];

          if (!this.fileList.length) {
            this.$message.error("请上传附件！");
            return;
          }

          let area1 = {};
          let area2 = {};
          let area3 = {};

          if (Array.isArray(values.area)) {
            const [areaId1, areaId2, areaId3] = values.area;
            area1 = this.rawAreaList.find((item) => item.id === areaId1);
            area2 = this.rawAreaList.find((item) => item.id === areaId2);
            area3 = this.rawAreaList.find((item) => item.id === areaId3);
          }

          const params = {
            // 经办
            operationDeptId: values.operationDeptId,
            operationDeptName: this.organizationList.find(
              (item) => item.id === values.operationDeptId
            )?.uniqueName,
            operatorId: this.selectedUser.userId,
            operatorName: this.selectedUser.name,

            contractCode: this.selectedContract.code,
            contractName: this.selectedContract.name,

            // 所属区域
            areaId: area1?.id,
            areaName: area1?.areaName,
            subAreaId: area2?.id,
            subAreaName: area2?.areaName,
            l3AreaId: area3?.id,
            l3AreaName: area3?.areaName,

            // 分包名称
            name: values.name,
            // 分包方
            companyId: this.selectedCompany.id,
            companyName: this.selectedCompany.name,
            // 合同金额
            actualAmount: values.actualAmount * 10000,
            // 用章名称
            sealType: values.sealType.join(),

            // 项目地点
            belongProvince: values.location[0],
            belongCity: values.location[1],
            district: values.location[2],
            // 国外
            isAbroad: values.isAbroad,

            isneeded: values.isneeded,
            type: values.type,
            major: values.major,
            paymentMode: values.paymentMode,

            attachments: this.fileList.map((item) => item.completePath).join(),

            subPackaged,
            subPackageList: this.subpackageList,
          };

          this.storageValue = params;
          this.saveLoading = true;

          update({
            ...params,
          })
            .then(() => {
              this.$close(this.$route.path);
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;

  .header {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #f0f0f0;
    color: #1890ff;
    font-weight: bold;
    font-size: 14px;

    img {
      width: 14px;
      margin-right: 4px;
    }

    .extra {
      margin-left: auto;
    }
  }

  .tip {
    color: #999;
  }
}

.center {
  margin-top: 80px;
  margin-bottom: 80px;
}
</style>
